<template>
  <div class="UiInputHtml">
    <textarea
      class="ui-inset"
      v-show="rawEditor"
      v-model="innerValue"
      @input="emitInput"
    ></textarea>

    <CmsHtmlEditor
      v-show="!rawEditor"
      class="ui-inset"
      v-model="innerValue"
      @input="emitInput"
    />

    <span
      class="UiInputHtml__contentSize"
      style="float:right"
    >
      {{innerValue.length}} b.
      <button
        type="button"
        @click="clean"
      >Limpiar</button>
    </span>

    <EditorToolbar />
  </div>
</template>

<script>
import CmsHtmlEditor from '@/modules/cms/components/Media/Html/HtmlEditor.vue';
import EditorToolbar from './EditorToolbar.vue';

export default {
  name: 'UiInputHtml',

  components: { CmsHtmlEditor, EditorToolbar },

  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      innerValue: '',
      rawEditor: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.innerValue = newValue || '';
      },
    },
  },

  methods: {
    emitInput() {
      this.$emit('input', this.innerValue);
    },

    clean() {
      let retval = '';
      this.$el.querySelectorAll('.editor-contents *').forEach((node) => {
        if (['B', 'P', 'LI', 'SPAN', 'H1', 'H2', 'H3'].includes(node.tagName)) {
          retval += `<${node.tagName}>${node.textContent}</${node.tagName}>`;
        }
        if (['A', 'IMG'].includes(node.tagName)) {
          node.innerHTML = node.textContent;
          retval += node.outerHTML;
        }
      });
      this.innerValue = retval;
    },
  },
};
</script>

<style lang="scss">
.UiInputHtml {
  min-height: 200px;
  max-height: 400px;
  position: relative;

  textarea,
  div[contenteditable] {
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    min-height: 200px;
    max-height: 360px;
    overflow: auto;
  }

  footer {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__contentSize {
    display: block;
    margin: 0;
    padding: 5px;
    font-size: 9pt;
  }
}
</style>