<template>
  <div
    class="cms-media-html-editor cms-media-html"
    :class="themeClass"
  >
    <div
      class="editor-contents"
      contenteditable="true"
      v-html="innerValue"
      @input="$emit('input', $event.target.innerHTML)"
      @keydown="$emit('keydown', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'cms-media-html-editor',

  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },

    theme: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      innerValue: ''
    };
  },

  watch: {
    value(newValue) {
      let innerHtml = this.$el.querySelector('.editor-contents').innerHTML;
      if (newValue == innerHtml) {
        return;
      }
      this.innerValue = newValue;

      // v-html deberia hacer esto pero parece estar fallando (!)
      this.$el.querySelector('.editor-contents').innerHTML = newValue;
    }
  },

  computed: {
    themeClass() {
      if (!this.theme) {
        return null;
      }
      return '--theme-' + this.theme;
    }
  },

  mounted() {
    this.innerValue = this.value;
    document.execCommand('defaultParagraphSeparator', false, 'p');
  }
};
</script>

<style lang="scss">
@import "./themes/index.scss";

.cms-media-html-editor {
  position: relative;

  img {
    max-width: 100% !important;
  }

  [contenteditable] {
    outline: none;
  }
}
</style>